import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './app.less';

//page
import asyncComponent from './component/async-component';
const placeholder = <span style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', alignItems: 'center', 'justifyContent': 'center' }}>loading……</span>

// PC
const PageHome = asyncComponent(() => import('./page/home'), placeholder);
const PageCases = asyncComponent(() => import('./page/cases'), placeholder);
const PageCaseDetail = asyncComponent(() => import('./page/cases/detail'), placeholder);
const PageAbout = asyncComponent(() => import('./page/about'), placeholder);
const PageContact = asyncComponent(() => import('./page/contact'), placeholder);
const PageInfo = asyncComponent(() => import('./page/info'), placeholder);

//Mobile
const PageMobileHome = asyncComponent(() => import('./mobilePage/home'), placeholder);
const PageMobileCases = asyncComponent(() => import('./mobilePage/cases'), placeholder);
const PageMobileCaseDetail = asyncComponent(() => import('./mobilePage/cases/detail'), placeholder);
const PageMobileAbout = asyncComponent(() => import('./mobilePage/about'), placeholder);
const PageMobileContact = asyncComponent(() => import('./mobilePage/contact'), placeholder);
const PageMobileInfo = asyncComponent(() => import('./mobilePage/info'), placeholder);

function App() {
  return (
	  <Router>
		  <div>
        <Route exact path="/" component={PageHome} />
        <Route exact path="/home" component={PageHome} />
        <Route exact path="/cases" component={PageCases} />
        <Route exact path="/case/:id" component={PageCaseDetail} />
        <Route exact path="/about" component={PageAbout} />
        <Route exact path="/contact" component={PageContact} />
        <Route exact path="/info" component={PageInfo} />
		  </div>
	  </Router>
  );
}

function MobileApp() {
  return (
    <Router>
      <div>
        <Route exact path="/" component={PageMobileHome} />
        <Route exact path="/home" component={PageMobileHome} />
        <Route exact path="/cases" component={PageMobileCases} />
        <Route exact path="/case/:id" component={PageMobileCaseDetail} />
        <Route exact path="/about" component={PageMobileAbout} />
        <Route exact path="/contact" component={PageMobileContact} />
        <Route exact path="/info" component={PageMobileInfo} />
      </div>
    </Router>
  );
}

export default window.isMobile ? MobileApp : App;
